import firebase from 'firebase';

export const authMix = {
    methods: {
        async firebaseAuth(store) {
            await firebase.auth().onAuthStateChanged(async (currentUser) => {
                if (currentUser) {
                    console.log('user is logged');
                    const user = await this.firebaseGetUser(currentUser.uid);
                    user.id = currentUser.uid;
                    store.commit("setUser", user);
                    return user;
                } else {
                    console.log('user is not logged');
                    return null;
                }
            });
        },

        firebaseLogout() {
            return firebase.auth().signOut()
        },

        firebaseLogin(email, password) {
            return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
                return firebase.auth().signInWithEmailAndPassword(email, password)
            }).catch((error) => {
                return error;
            });
        },

        firebaseRegister(email, password) {
            const cred = firebase.auth().createUserWithEmailAndPassword(email, password);
            this.firebaseLogin(email, password);
            return cred;
        },

        firebaseSaveUser(user_id, data) {
            return firebase.firestore().collection('users').doc(user_id).update(data);
        },

        firebaseUpdateUserProfile(user_id, data, newProfile = null) {
            if (newProfile) {
                firebase.auth().currentUser.updatePhoneNumber(newProfile.phoneNumber);
                firebase.auth().currentUser.updateProfile(newProfile);
            }
            return this.firebaseSaveUser(user_id, data);
        },

        firebaseCreateUser(user_id, data, displayName, email) {
            firebase.auth().currentUser.updateProfile({ displayName })
                .then(() => { this.firebaseSendVerification() });
            firebase.database().ref('users').child(user_id).set({
                displayName,
                email,
                phoneNumber: '',
                imageUrl: '',
                dni: data.dni,
                id: user_id,
                isDoctor: data.isDoctor,
            });
            delete data.dni;
            return firebase.firestore().collection('users').doc(user_id).set(data);
        },

        firebaseAddSub(user_id, token) {
            firebase.database().ref('users').child(user_id).update({
                subToken: token,
            });
        },

        firebaseCurrentUser() {
            return firebase.auth().currentUser;
        },

        firebaseGetUser(user_id) {
            return firebase.firestore().collection('users').doc(user_id).get().then(doc => {
                return doc.data();
            });
        },

        firebaseSendVerification() {
            return firebase.auth().currentUser.sendEmailVerification();
        },

        firebaseSendResetPassword(email) {
            return firebase.auth().sendPasswordResetEmail(email);
        },

        firebaseChangePassword(newPassword, user_id) {
            firebase.auth().currentUser.updatePassword(newPassword);
            // firebase.auth().confirmPasswordReset()
            return firebase.firestore().collection('users').doc(user_id).update({ password: newPassword, recovery: false });
        },

        firebaseGetProfileImg(user_id) {
            return firebase.storage().ref("users/" + user_id + "/profile.jpg").getDownloadURL().then(imgUrl => {
                return imgUrl;
            }).catch((error) => {
                console.log(error);
                return "https://upload.wikimedia.org/wikipedia/commons/3/33/Mr._Bean_2011.jpg";
            });
        },

        firebaseSetProfileImg(user_id, file) {
            return firebase.storage().ref("users/" + user_id + "/profile.jpg").put(file).then(async () => {
                const img = await this.firebaseGetProfileImg(user_id);
                firebase.auth().currentUser.updateProfile({ photoURL: img });
                firebase.database().ref('users').child(user_id).update({ profileImg: img })
            });
        },

        getUser(user_id) {
            return firebase.database().ref('users').child(user_id).get().then(doc => {
                return doc.val();
            });
        },

        getAllUsers() {
            const users = [];
            firebase.database().ref('users').get().then(doc => {
                doc.forEach(a => {
                    users.push(a.val());
                });
            });

            return users;
        },

        getUsers() {
            return firebase.firestore().collection('users').get().then(doc => {
                const docs = [], ids = [];
                doc.docs.forEach((doc) => {
                    ids.push(doc.id);
                    docs.push(doc.data());
                });
                docs.forEach((doc, ind) => {
                    doc.id = ids[ind];
                });
                return docs;
            });
        },
    }
}

export const protocolMix = {
    methods: {
        sendEmail(data) {
            return firebase.firestore().collection('interventions')
                .add(data);
        },
        generateProtocol(data) {
            return firebase.firestore().collection('protocols').add(data);
        },

        getProtocol(protocol_id) {
            let ref = firebase.firestore().collection('protocols')
                .doc(protocol_id);
            return ref.get().then(doc => {
                const docs = [];
                const protocol = doc.data();
                protocol.id = doc.id;
                docs.push(protocol);
                return docs;
            });
        },

        async getProtocols(user_id) {
            let ref = firebase.firestore().collection('protocols');
            if (user_id) {
                ref = ref.where('created_by', "==", user_id);
            }
            return ref.get().then(doc => {
                const docs = [], ids = [];
                doc.docs.forEach((doc) => {
                    ids.push(doc.id);
                    docs.push(doc.data());
                });
                docs.forEach((doc, ind) => {
                    doc.id = ids[ind];
                });
                return docs;
            });
        },

        assignProtocol(user_id, data) {
            return firebase.firestore().collection('users')
                .doc(user_id).collection('protocols').add(data);
        },

        async getStandardProtocols() {
            return await firebase.firestore().collection('standardForms').get()
                .then((doc) => {
                    const docs = [];
                    doc.docs.forEach((doc) => {
                        const stdForm = doc.data();
                        stdForm.id = doc.id;
                        docs.push({ name: stdForm.name, form: stdForm, isStandard: stdForm.isStandard });
                    });
                    return docs;
                });
        },

        addResult(data) {
            return firebase.firestore().collection('results')
                .add(data);
        },

        async getResult(result_id) {
            const result = await firebase.firestore().collection('results')
                .doc(result_id).get();
            return result.data();
        },

        updateProtocolData(protocol_id, data) {
            return firebase.firestore().collection('protocols')
                .doc(protocol_id).set(data);
        },

        async updateProtocol(user_id, protocol_id, data) {
            return await firebase.firestore().collection('users')
                .doc(user_id).collection('protocols').doc(protocol_id).set(
                    data
                ).then(() => true).catch(() => false);
        },

        deleteProtocol(protocol_id) {
            let ref = firebase.firestore().collection('protocols')
                .doc(protocol_id);
            ref.delete();
        }
    }
}

export const patientMix = {
    methods: {
        assignPatient(user_id, data) {
            return firebase.firestore().collection('users').doc(user_id)
                .collection('patients').doc(data.patient).set(data);
        },

        async getAllPatients(user_id) {
            const patients = await firebase.firestore().collection('users').doc(user_id)
                .collection('patients').get().then((doc) => {
                    const ids = [];
                    doc.docs.forEach((document) => {
                        ids.push(document.id);
                    })
                    return ids;
                }).then(async (patients_ids) => {
                    return await this.getPatientsData(patients_ids);
                });

            return patients;

        },


        async waitForPatients(patients_ids) {
            return new Promise((resolve) => {
                const patients = [];
                for (let index = 0; index < patients_ids.length; index++) {
                    const id = patients_ids[index];
                    const userData = authMix.methods.firebaseGetUser(id);
                    const interventions = this.getInterventions(id);
                    firebase.database().ref('users').child(id).get().then(doc => {
                        const docData = doc.val();
                        if (id === "wCWNQrCfmF9wGHiKI3IJ") {
                            patients.push({ ...{ displayName: "Usuarios no registrados", id }, ...docData, ...userData, ...{ interventions } });
                        } else {
                            patients.push({ ...docData, ...userData, ...{ interventions } });
                        }
                    });
                    // await callback(patients_ids[index], index, patients_ids);
                }
                resolve(patients);
            })
        },

        async getPatientsData(patients_ids) {
            const patients = [];
            for (let index = 0; index < patients_ids.length; index++) {
                const id = patients_ids[index];
                const userData = await authMix.methods.firebaseGetUser(id);
                const interventions = await this.getInterventions(id);
                await firebase.database().ref('users').child(id).get().then(doc => {
                    const docData = doc.val();
                    if (id === "wCWNQrCfmF9wGHiKI3IJ") {
                        patients.push({ ...{ displayName: "Usuarios no registrados", id }, ...docData, ...userData, ...{ interventions } });
                    } else {
                        patients.push({ ...docData, ...userData, ...{ interventions } });
                    }
                });
                // await callback(patients_ids[index], index, patients_ids);
            }
            setTimeout(() => {}, 5000);
            return patients;


            // await patients_ids.forEach(async (id) => {
            //     const userData = await authMix.methods.firebaseGetUser(id);
            //     const interventions = await this.getInterventions(id);
            //     await firebase.database().ref('users').child(id).get().then(doc => {
            //         const docData = doc.val();
            //         patients.push({ ...docData, ...userData, ...{ interventions } });
            //     });
            // });
            // if (patients.length === patients_ids.length) {
            //     return patients;
            // }
        },

        async getInterventions(user_id) {
            return await firebase.firestore().collection('users').doc(user_id)
                .collection('protocols').get().then(doc => {
                    const docs = [];
                    doc.docs.forEach((doc) => {
                        const inter = doc.data();
                        inter.id = doc.id;
                        docs.push(inter);
                    });
                    return docs;
                });
        },

        async updateIntervention(user_id, protocol_id, data) {
            const intervention = await firebase.firestore().collection('users').doc(user_id)
                .collection('protocols').doc(protocol_id);
            await intervention.update(data);
        },

        async getFilteredInterventions(user_id, protocol_id, isSingle = false) {
            if (isSingle) {
                return await firebase.firestore().collection('users').doc(user_id)
                    .collection('protocols').doc(protocol_id).get().then(doc => {
                        // const docs = [];
                        // doc.docs.forEach((doc) => {
                        const inter = doc.data();
                        inter.id = doc.id;
                        // docs.push(inter);
                        // });
                        return inter;
                    });
            } else {
                return await firebase.firestore().collection('users').doc(user_id)
                    .collection('protocols').where('protocol', '==', protocol_id).get().then(doc => {
                        const docs = [];
                        doc.docs.forEach((doc) => {
                            const inter = doc.data();
                            inter.id = doc.id;
                            docs.push(inter);
                        });
                        return docs;
                    });
            }

        }
    }
}

export const doctorMix = {
    methods: {
        async getDoctors(user_id) {
            let ref = firebase.firestore().collection('users').where('isDoctor', "==", true);
            const aDoctors = await ref.get();
            return this.searchDoctorData(aDoctors, user_id);
        },

        async searchDoctorData(aDoctors, user_id) {
            const drs = [];
            for (const doc of aDoctors.docs) {
                const aPatients = await doc.ref.collection("patients").doc(user_id).get();
                if (aPatients.exists) {
                    const doctor = doc.data();
                    doctor.id = doc.id;
                    await firebase.database().ref('users').child(doctor.id).get().then(d => {
                        const docData = d.val();
                        drs.push({ ...docData, ...doctor });
                    });
                }
            }
            return drs;
        }
    }
}

export const groupMix = {
    methods: {
        createGroup(data) {
            return firebase.firestore().collection('groups').add(data);
        },

        getGroups(user_id) {
            let ref = firebase.firestore().collection('groups');
            if (user_id) {
                ref = ref.where('created_by', "==", user_id);
            }
            return ref.get().then(doc => {
                const docs = [], ids = [];
                doc.docs.forEach((doc) => {
                    ids.push(doc.id);
                    docs.push(doc.data());
                });
                docs.forEach((doc, ind) => {
                    doc.id = ids[ind];
                });
                return docs;
            });
        }
    }
}

export const folderMix = {
    methods: {
        async getFolders() {
            const folders = [];
            let ref = firebase.firestore().collection('folders2');
            await ref.get().then(doc => {

                doc.forEach((a) => {
                    const b = a.data();
                    b.id = a.id;
                    folders.push(b);
                });
            });
            return folders.length > 0  && folders[0].folders ? folders[0].folders : [];
        },

        async getFolders2() {
            const folders = [];
            let ref = firebase.firestore().collection('folders');
            await ref.get().then(doc => {

                doc.forEach((a) => {
                    const b = a.data();
                    b.id = a.id;
                    folders.push(b);
                });
            });
            return folders.length > 0  && folders[0].folders ? folders[0].folders : [];
        }
    }
}

export const sheetQMix = {
    methods: {
        async getSheets() {
            const folders = [];
            let ref = firebase.firestore().collection('folders');
            await ref.get().then(doc => {

                doc.forEach((a) => {
                    const b = a.data();
                    b.id = a.id;
                    folders.push(b);
                });
            });
            return folders.length > 0  && folders[0].folders ? folders[0].folders : [];
        }
    }
}

export const databaseMix = {
    methods: {
        saveFilterParams(data) {
            return firebase.firestore().collection('database').add(data);
        },

        getFilterParams() {
            return firebase.firestore().collection('database').get().then(doc => {
                const docs = [];
                doc.docs.forEach((doc) => {
                    const params = doc.data();
                    params.id = doc.id;
                    docs.push(params);
                });
                return docs;
            });
        }
    }
}

export const globalMix = {
    methods: {
        async getHome(isDoctor) {
            const homes = [];
            const docId = isDoctor ? "iAzpPYQvoHfOk3W6i1Wc" : "2eljMfwDiJbd87aoUc7F";
            await firebase
                .firestore()
                .collection('home')
                .doc(docId)
                .get()
                .then((doc) => {
                    homes.push(doc.data());
                });
            return homes;
        }
    }
}