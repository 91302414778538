<template>
  <div id="app">
    <NavBar />
    <SideBar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "./components/SideBar.vue";
import Cookies from "./components/Cookies.vue";
import { authMix } from "@/mixins/mixins.js";

export default {
  name: "App",
  mixins: [authMix],
  components: {
    NavBar,
    Footer,
    SideBar
  },
  methods: {
    onClick(a) {
      console.log(a);
    }
  },
  async mounted() {
    const cUser = await this.firebaseCurrentUser();
    const token = localStorage.getItem("gpush_token");
    if (cUser && cUser.uid && token) {
      await this.firebaseAddSub(cUser.uid, token);
    }

    const userCookie = JSON.parse(localStorage.getItem("cookies_global"));
    console.log(userCookie);
    if (!userCookie || (userCookie && !userCookie.value)) {
      this.$vs.notification({
        duration: "none",
        content: Cookies,
        text: "Condiciones de uso, cookies y aspectos legales",
        title: "Bienvenido a Traumareports",
        buttonClose: false,
        color: "dark",
        icon:
          '<img lazy width="100%" src="https://cdn-icons-png.flaticon.com/512/1047/1047711.png" alt="" srcset="">',
        onClick: this.onClick
      });
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Montserrat");

#app {
  font-family: "Montserrat", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
