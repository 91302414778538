<template>
    <div>
        <br>
        <div>
            <small>En este sitio utilizamos cookies para mejorar 
                la experiencia de usuario, por ello debes de aceptar explicitamente las 
                <a href="https://traumareports.com/politic">Condiciones de uso</a> y 
                <a href="https://traumareports.com/legal">Aspectos legales</a> 
                o rechazar y salir.</small>
        </div>
        <div style="display:flex; justify-content: space-between;">
            <vs-button transparent danger @click="onReadPolitics">Rechazar</vs-button>
            <vs-button transparent success @click="onAccept">Acceptar</vs-button>
        </div>
    </div>
</template>
<script>
export default {
    name: "Cookies",
    mixins: [],
    components: {},
    methods: {
        onAccept(){
            localStorage.setItem("cookies_global",JSON.stringify({value:true, date: new Date().toUTCString()}));
            this.$root.close();
        },
        onReadPolitics(){
            localStorage.setItem("cookies_global",JSON.stringify({value:false, date: new Date().toUTCString()}));
            this.$root.close();
        }
    }
}
</script>