import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import firebase from '@/firebase';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/group',
    name: 'Group',
    component: () => import(/* webpackChunkName: "group" */ '../views/Group.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/patients',
    name: 'Patients',
    component: () => import(/* webpackChunkName: "patients" */ '../views/Patients.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/doctors',
    name: 'Doctors',
    component: () => import(/* webpackChunkName: "doctors" */ '../views/Doctors.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/interventions',
    name: 'Interventions',
    component: () => import(/* webpackChunkName: "interventions" */ '../views/Interventions.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/protocols',
    name: 'Protocols',
    component: () => import(/* webpackChunkName: "protocols" */ '../views/Protocols.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/database',
    name: 'Database',
    component: () => import(/* webpackChunkName: "database" */ '../views/Database.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: () => import(/* webpackChunkName: "agenda" */ '../views/Agenda.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/legal',
    name: 'Legal',
    component: () => import(/* webpackChunkName: "legal" */ '../views/Legal.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/politic',
    name: 'Politic',
    component: () => import(/* webpackChunkName: "politic" */ '../views/Politic.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  if (requiresAuth && !firebase.auth().currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
