<template>
  <div class="home">
    <!-- <header>
      <h2>Trauma Reports</h2>
    </header> -->
    <div class="mainPage">
      <div v-html="htmlString" />
    </div>

    <!-- <img
      class="portadaImg"
      src="https://aepsad.culturaydeporte.gob.es/dam/jcr:6c17382f-61d3-454b-a5d0-b990a5191cb3/proteccion-trabajo-covid-19.jpeg"
    /> -->
  </div>
</template>

<script>
import { globalMix, authMix } from "@/mixins/mixins.js";
export default {
  name: "Home",
  mixins: [globalMix, authMix],
  data() {
    return {
      htmlString: "<h1></h1>",
      isDoctor: false,
    };
  },
  components: {},
  async mounted() {
    const { currentUserInfo } = this.$store.state;
    if (currentUserInfo) {
      const { uid } = currentUserInfo;
      const userData = await this.firebaseGetUser(uid);
      this.isDoctor = userData ? userData.isDoctor : false;
    }
    const a = await this.getHome(this.isDoctor);
    this.htmlString = a[0].content;
  },
};
</script>

<style scoped>
@media only screen and (hover: none) and (pointer: coarse) {
  /* Regular CSS rules here*/
  h1 {
    font-size: 2rem;
  }

  p {
    text-align: justify;
    padding: 0.5rem;
  }
}
.mainPage {
  border-radius: 3rem;
  background: #ffffff;
  height: 100%;
  padding: 3rem 1rem;
}

.home {
  background: linear-gradient(180deg, #cfdfe1, #ffffff);
  padding: 4rem 1rem 0rem 4.5rem;
  height: calc(100vh - (56px+24px) - 8.5rem);
}

.portadaImg {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
</style>
