<template>
  <div class="footer">
    &copy; 2022 - Trauma Reports
    <!-- <a target="_blank" href="http://www.softonickdev.com"></a> -->
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  background: #17a2b8;
  width: 100%;
  z-index: 10;
  color: #fff
}

.footer > a {
  color: whitesmoke;
}
</style>
