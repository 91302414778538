import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyDHj00WXvYEEhNnUgWnU44srreBaou9Ifk",
    authDomain: "clinikform.firebaseapp.com",
    projectId: "clinikform",
    databaseURL: "https://clinikform-default-rtdb.europe-west1.firebasedatabase.app",
    storageBucket: "clinikform.appspot.com",
    messagingSenderId: "462429410606",
    appId: "1:462429410606:web:45fa0cd2fb755f9f22d6bf",
    measurementId: "G-F1E4JH7M5G"
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

if (Notification.permission !== "granted") {
    Notification.requestPermission().then(() => {
        const vapidKey = "BGWdwUAyIWezdLSfJQGW_s-sM3DZ-c_oQbk2VCwD0Nyq5_V5DL9QjDWBEu1voE-0URztsLItblGyAaxwhGiXTNI";
        messaging.getToken({ vapidKey }).then((userToken) => {
            // console.log(userToken);
            localStorage.setItem("gpush_token", userToken);
        }).catch((err) => console.log(err));
    });
} else {
    const vapidKey = "BGWdwUAyIWezdLSfJQGW_s-sM3DZ-c_oQbk2VCwD0Nyq5_V5DL9QjDWBEu1voE-0URztsLItblGyAaxwhGiXTNI";
    messaging.getToken({ vapidKey }).then((userToken) => {
        // console.log(userToken);
        localStorage.setItem("gpush_token", userToken);
    }).catch((err) => console.log(err));
}


messaging.onMessage((payload) => {
    console.log('Message received. ', payload);
    // ...
});



// const auth = firebase.auth()

// export default { auth, firebase };
export default firebase;