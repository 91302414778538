<template>
  <vs-sidebar
    ref="sidebar"
    class="sidebar"
    hoverExpand
    :reduce="isReduce"
    v-model="active"
    open
  >
    <template #logo>
      <vs-avatar v-if="isDoctor && reduceInternal" size="110">
        <img
          class="avatarImg"
          height="100%"
          width="100%"
          :src="
            imageUrl ||
              'https://image.freepik.com/vector-gratis/doctor-icono-o-avatar-blanco_136162-58.jpg'
          "
          alt=""
        />
      </vs-avatar>
      <vs-avatar v-else size="110">
        <img
          class="avatarImg"
          height="100%"
          width="100%"
          :src="
            imageUrl ||
              'https://www.coprivado.com.ar/panel_control/files/novedades/194_avatar.png'
          "
          alt=""
        />
      </vs-avatar>
    </template>
    <template #header>
      {{ displayName || "User" }}
    </template>
    <vs-sidebar-item id="home" to="/">
      <template #icon>
        <i class="fas fa-home"></i>
      </template>
      Home
    </vs-sidebar-item>
    <vs-sidebar-item id="myProfile" to="/profile">
      <template #icon>
        <i class="fas fa-id-card-alt"></i>
      </template>
      Mi perfil
    </vs-sidebar-item>
    <vs-sidebar-item v-if="isDoctor" id="myGroups" to="/group">
      <template #icon>
        <i class="fas fa-users"></i>
      </template>
      Mis grupos
    </vs-sidebar-item>
    <vs-sidebar-item v-if="isDoctor" id="myUsers" to="/patients">
      <template #icon>
        <i class="fas fa-user-injured"></i>
      </template>
      Mis pacientes
    </vs-sidebar-item>
    <vs-sidebar-item v-if="!isDoctor" id="myUsers" to="/doctors">
      <template #icon>
        <i class="fas fa-user-nurse"></i>
      </template>
      Mis médicos
    </vs-sidebar-item>
    <vs-sidebar-item v-if="isDoctor" id="myDatabase" to="/database">
      <template #icon>
        <i class="fas fa-database"></i>
      </template>
      Base de datos
    </vs-sidebar-item>
    <vs-sidebar-item v-if="!isDoctor" id="myDatabase" to="/interventions">
      <template #icon>
        <i class="fas fa-hospital-alt"></i>
      </template>
      Mis intervenciones
    </vs-sidebar-item>
    <vs-sidebar-item v-if="isDoctor" id="myProtocols" to="/protocols">
      <template #icon>
        <i class="fas fa-file-medical"></i>
      </template>
      Mis protocolos
    </vs-sidebar-item>
    <vs-sidebar-item v-if="!isDoctor" id="myProtocols" to="/agenda">
      <template #icon>
        <i class="fas fa-notes-medical"></i>
      </template>
      Próximos controles
    </vs-sidebar-item>
    <!-- <vs-sidebar-item id="market">
      <template #icon>
        <i class="bx bx-grid-alt"></i>
      </template>
      Market Overview
    </vs-sidebar-item>
    <vs-sidebar-item id="Music">
      <template #icon>
        <i class="bx bxs-music"></i>
      </template>
      Music
    </vs-sidebar-item>
    <vs-sidebar-item id="donate">
      <template #icon>
        <i class="bx bxs-donate-heart"></i>
      </template>
      Donate
    </vs-sidebar-item>
    <vs-sidebar-item id="drink">
      <template #icon>
        <i class="bx bx-drink"></i>
      </template>
      Drink
    </vs-sidebar-item>
    <vs-sidebar-item id="shopping">
      <template #icon>
        <i class="bx bxs-shopping-bags"></i>
      </template>
      Shopping
    </vs-sidebar-item>
    <vs-sidebar-item id="chat">
      <template #icon>
        <i class="bx bx-chat"></i>
      </template>
      Chat
    </vs-sidebar-item> -->
    <vs-sidebar-group>
      <template #header>
        <vs-sidebar-item arrow>
          <template #icon>
            <!-- <i class="fas fa-shield-check"></i> -->
            <i class="fas fa-check-circle"></i>
          </template> 
          Datos y Seguridad
        </vs-sidebar-item>
      </template>

      <vs-sidebar-item id="legal" to="/legal">
        <template #icon>
          <i class="fas fa-balance-scale"></i>
        </template>
        Aviso legal
      </vs-sidebar-item>
      <vs-sidebar-item id="privacity" to="/politic">
        <template #icon>
          <i class="fas fa-user-secret"></i>
        </template>
        Privacidad
      </vs-sidebar-item>
      <vs-sidebar-item id="myData">
        <template #icon>
          <i class="fas fa-address-card"></i>
        </template>
        Mis datos
      </vs-sidebar-item>
    </vs-sidebar-group>
    <template #footer>
      <!-- <vs-row justify="space-between">
        <vs-avatar>
          <img src="/avatars/avatar-5.png" alt="" />
        </vs-avatar>

        <vs-avatar badge-color="danger" badge-position="top-right">
          <i class="bx bx-bell"></i>

          <template #badge>
            28
          </template>
        </vs-avatar>
    
      </vs-row> -->
      <vs-button danger block @click="onLogout">
        Logout
      </vs-button>
    </template>
  </vs-sidebar>
</template>

<script>
import { authMix } from "@/mixins/mixins.js";
import { mapState } from "vuex";

export default {
  name: "SideBar",
  mixins: [authMix],
  data() {
    const { reduceInternal } = this.$refs.sidebar
      ? this.$refs.sidebar.$data
      : false;
    return {
      isDoctor: false,
      active: true,
      isReduce: true,
      displayName: "Nombre de usuario",
      imageUrl: null,
      reduceInternal
    };
  },
  methods: {
    async getInitData() {
      const { currentUserInfo } = this.$store.state;
      if (currentUserInfo) {
        const { displayName, uid, photoURL } = currentUserInfo;
        this.imageUrl = photoURL;
        this.displayName = displayName || "Nombre de usuario";
        const userData = await this.firebaseGetUser(uid);
        this.isDoctor = userData ? userData.isDoctor : false;
      }
    },
    async onLogout() {
      await this.firebaseLogout();
      this.$store.commit("setUserInfo", {});
      this.$router.push("/#");
    }
  },
  mounted() {
    this.isReduce = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
    this.getInitData();
  },
  computed: {
    ...mapState(["currentUserInfo"])
  },

  watch: {
    async currentUserInfo(newValue) {
      if (newValue.user) {
        const currentUserInfo = newValue.user;
        const { displayName, uid, photoURL } = currentUserInfo;
        this.imageUrl = photoURL;
        this.displayName = displayName || "Nombre de usuario";
        const userData = await this.firebaseGetUser(uid);
        this.isDoctor = userData.isDoctor;
      } else if (newValue.uid) {
        const currentUserInfo = newValue;
        const { displayName, uid, photoURL } = currentUserInfo;
        this.imageUrl = photoURL;
        this.displayName = displayName || "Nombre de usuario";
        const userData = await this.firebaseGetUser(uid);
        this.isDoctor = userData.isDoctor;
      }
    },
    handleOpen() {}
  }
};
</script>

<style scoped>
.sidebar {
  top: 56px;
  height: calc(100vh - (56px + 24px)) !important;
}

.vs-sidebar-content.reduce .vs-sidebar__logo img {
  width: 100% !important;
  height: 50px !important;
}

.vs-sidebar-content.reduce > .vs-sidebar__header {
  font-size: x-small;
}

.avatarImg {
  max-height: none !important;
  max-width: none !important;
  height: 100% !important;
  width: 100% !important;
}
</style>
